<template>
  <div class="home">
    <v-container class="text-center" fluid>
      <v-row wrap>
        <v-col md="4" sm="12">
          <v-hover v-slot="{ hover }" @input="hoverOver('hoverOne', $event)">
            <v-card
            :elevation="hover ? 12 : 6"
            :class="{ 'on-hover': hoverOne }"
            to="/customers"
            >
              <v-img
                src="../assets/pfsimages/CustomerPlans.jpg"
                height="400px"
              >
                <v-card-title class="white--text fill-height">
                  <v-row
                    justify="center"
                  >
                    <h1>Customer Plans</h1>
                  </v-row>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
        <v-col md="4" sm="12">
          <v-hover v-slot="{ hover }" @input="hoverOver('hoverTwo', $event)">
          <v-card
            :elevation="hover ? 12 : 6"
            :class="{ 'on-hover': hoverTwo }"
            to="/prospect"
            >
              <v-img
                src="../assets/pfsimages/ProspectingByField.jpg"
                height="400px"
              >
                <v-card-title class="white--text fill-height">
                  <v-row
                    justify="center"
                  >
                    <h1>Prospect by Field</h1>
                  </v-row>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
        <v-col md="4" sm="12">
           <v-hover v-slot="{ hover }" @input="hoverOver('hoverThree', $event)">
            <v-card
            :elevation="hover ? 12 : 6"
            :class="{ 'on-hover': hoverThree }"
            to="/tutorial"
            >
              <v-img
                height="400px"
              >
                <v-card-title class="white--text fill-height">
                  <v-row
                    justify="center"
                  >
                    <h1>Tutorial</h1>
                  </v-row>
                </v-card-title>
              </v-img>
            </v-card>
           </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      hoverOne: false,
      hoverTwo: false,
      hoverThree: false,
      boxes: ["hoverOne", "hoverTwo", "hoverThree"]
    }
  },
  created() {
  },
  watch: {
  },
  methods: {
    hoverOver(val, event) {
      if (!event) {
        this.boxes.map(item => this[item] = false)
      }
      else {
        let notHighlighted = this.boxes.filter(item => item !== val)
        notHighlighted.map(item => this[item] = true)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .homepage-buttons {
    margin-top:50px;
    color:white;
    width:150px;
    transform: scale(2);
    padding: 50px 0px 50px 0px;    
  }
  .v-card {
    transition: opacity .4s ease-out;
    cursor: pointer;
    .v-card__title{
      align-items: flex-end;
    }
  }
  h1 {
    // border: 1px solid;
    padding: .5rem 1rem;
    // background-color: #0b3a7f;
    text-transform: uppercase;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  .on-hover {
    opacity: 0.7;
  }
</style>
